import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import links from '../../../routes/links';
import setAcessibilityFocus from '../../../utils/acessibilityFocusMainContent';
import NavDropdownI18n from '../NavDropdownI18n';

import { nwLogo } from '../../../assets/images/commons';
import CTAPurple from '../CTA/Purple';

import { pushDataLayer } from '../../../utils/pushDataLayer';
import CTAScheduling from '../CTA/Scheduling';
import IconLogin from '../Icons/IconLogin';
import MenuCollapse from '../MenuCollapse';
import './styles.css';

const getNavDropdownTitle = (title) => (
  <>
    {title}
    <FontAwesomeIcon icon={faChevronDown} />
  </>
);

function Menu({ refParent }) {
  const { t } = useTranslation();
  const history = useHistory();
  const acessibilityFocus = useCallback((e) => {
    e.preventDefault();
    setAcessibilityFocus();
  }, []);

  const [pathname, SetPathname] = useState(history?.location.pathname);

  useEffect(() => {
    return history?.listen?.((location) => {
      SetPathname(location.pathname);
    });
  }, []);

  const institutionalPath = Object.values(links.institutional);
  const resourcesPath = Object.values(links.blog);

  const pushTo = (path) => {
    if (pathname !== path) history.push(path);
  };

  const dataLayerCategory = 'menu';

  const resourcesRef = useRef();

  const institutionalRef = useRef();

  const pushDataLayerHome = useCallback(() => {
    pushDataLayer(dataLayerCategory, 'home');
    SetPathname(links.home.root);
  }, []);

  const pushToCB = useCallback((link) => {
    pushTo(link);
  }, []);

  const getPushToCB = (link) => () => pushToCB(link);

  const pushDataLayerWithWindow = useCallback((e, menu, ref, target) => {
    e.preventDefault();
    pushDataLayer(dataLayerCategory, menu);
    window.open(ref, target);
  }, []);

  const getPushDataLayerWithWindow =
    (menu, ref, target = '') =>
    (e) =>
      pushDataLayerWithWindow(e, menu, ref, target);

  const pushDataLayerWithRef = useCallback((e, menu, ref) => {
    if (e.target === ref.current.firstChild) {
      e.preventDefault();
      pushDataLayer(dataLayerCategory, menu);
    }
  }, []);

  const getPushDataLayerWithRef = (menu, ref) => (e) =>
    pushDataLayerWithRef(e, menu, ref);

  const pushDataLayerTo = useCallback(
    (e, menu, link) => {
      e.preventDefault();
      pushDataLayer(dataLayerCategory, menu);
      pushTo(link);
    },
    [pushTo]
  );

  const getPushDataLayerTo = (menu, link) => (e) =>
    pushDataLayerTo(e, menu, link);

  return (
    <MenuCollapse refParent={refParent}>
      <Container>
        <h2 id="navbar__title" className="hidden">
          Navegação do site
        </h2>
        <Nav.Link
          href="#"
          onClick={acessibilityFocus}
          className="hide-visibility"
          tabIndex="0"
          id="go-to-main-content"
        >
          {t('common:menuGoToMainContent')}
        </Nav.Link>
        <Navbar.Brand className="">
          <Link to={links.home.root} onClick={pushDataLayerHome}>
            <span className="hide-visibility">{t('common:menuHome')}</span>
            <img src={nwLogo} alt="" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavDropdown
              title={getNavDropdownTitle(t('common:menuProdutos'))}
              className="nav-item"
              active={resourcesPath.includes(pathname)}
              ref={resourcesRef}
              onClick={getPushDataLayerWithRef('recursos', resourcesRef)}
            >
              <NavDropdown.Item
                href="https://materiais.gruponewway.com.br/cosmos"
                target="_blank"
                onClick={getPushDataLayerWithWindow(
                  'cosmos',
                  'https://materiais.gruponewway.com.br/cosmos',
                  '_blank'
                )}
              >
                {t('common:menuCosmos')}
              </NavDropdown.Item>
              <NavDropdown.Item
                href={links.mktzap.root}
                onClick={getPushDataLayerTo('mktzap', links.mktzap.root)}
                active={pathname === links.mktzap.root}
              >
                {t('common:menuMktzap')}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              className="nav-item"
              href={links.plans.root}
              onClick={getPushDataLayerTo('precos', links.plans.root)}
              active={pathname === links.plans.root}
            >
              {t('common:menuPrecos')}
            </Nav.Link>
            <Nav.Link
              className="nav-item"
              href={links.blog.root}
              onClick={getPushDataLayerTo('recursos_blog', links.blog.root)}
              active={pathname === links.blog.root}
            >
              {t('common:menuBlog')}
            </Nav.Link>
            <NavDropdown
              title={getNavDropdownTitle(t('common:menuInstitutional'))}
              className="nav-item"
              active={institutionalPath.includes(pathname)}
              ref={institutionalRef}
              onClick={getPushDataLayerWithRef(
                'institucional',
                institutionalRef
              )}
            >
              <NavDropdown.Item
                href={links.institutional.root}
                onClick={getPushDataLayerTo(
                  'institucional_quem-somos',
                  links.institutional.root
                )}
                active={pathname === links.institutional.root}
              >
                {t('common:menuWhoWeArea')}
              </NavDropdown.Item>

              <NavDropdown.Item
                href={t('home:jobs.link')}
                onClick={getPushDataLayerWithWindow(
                  'institucional_trabalhe-conosco',
                  t('home:jobs.link'),
                  '_blank'
                )}
              >
                {t('common:menuWorkWithUs')}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              className="nav-item"
              href={links.institutional.institute.root}
              onClick={getPushDataLayerTo(
                'institucional_instituto',
                links.institutional.institute.root
              )}
              active={pathname === links.institutional.institute.root}
            >
              {t('common:menuInstitutionNewWay')}
            </Nav.Link>
            <NavDropdownI18n
              getNavDropdownTitle={getNavDropdownTitle}
              gtmCategory="menu"
            />
            <div className="nav-actions">
              <Nav.Item className="nav-link">
                <CTAPurple
                  className="enter-mktzap-button"
                  variant="custom"
                  onClick={getPushDataLayerWithWindow(
                    'login_mktzap',
                    t('entrarMktzap:mktzapLink'),
                    '_blank'
                  )}
                >
                  {t('common:btnEntrar')}
                  <IconLogin />
                </CTAPurple>
              </Nav.Item>
              <Nav.Item className="nav-link">
                <CTAScheduling
                  className="btn-agendamento"
                  variant="tertiary"
                  onClick={getPushToCB(links.scheduling.root)}
                  eventProps={{
                    category: dataLayerCategory,
                    label: 'solicitar-cotacao',
                  }}
                />
              </Nav.Item>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </MenuCollapse>
  );
}

export default Menu;
