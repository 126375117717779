import React, { useCallback, useEffect, useState } from 'react';

import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '@gruponewway/aquisicao-base-footer';
import '@gruponewway/aquisicao-base-footer/dist/index.css';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { nwLogo } from '../../../assets/images/commons';
import links from '../../../routes/links';

import { pushDataLayer } from '../../../utils/pushDataLayer';
import './styles.css';

function FooterNwSite() {
  const { t } = useTranslation();
  const history = useHistory();
  const [pathname, SetPathname] = useState(history?.location.pathname);

  const dataLayerCategory = 'footer';

  useEffect(() => {
    return history?.listen?.((location) => {
      SetPathname(location.pathname);
    });
  }, []);

  const pushTo = (path) => {
    if (pathname !== path) history.push(path);
    else window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const onClickLogo = useCallback(
    (e) => {
      e.preventDefault();
      pushDataLayer('footer', 'logo_new-way');
      history.push(links.home.root);
    },
    [history]
  );

  return (
    <Footer
      logoNavigationRoute={links.home.root}
      nwLogo={nwLogo}
      onLogoClick={onClickLogo}
      menuHome={t('common:menuHome')}
      commonNewWay={t('common:newWay')}
      commonTerms={t('common:terms')}
    >
      <Container className="footer-layout">
        <nav className="footer-menu" aria-labelledby="footer-menu__title">
          <h2 id="footer-menu__title" className="hidden">
            Navegação do site no rodapé
          </h2>
          <div className="footer-menu-column">
            <h2>
              <a
                href={links.mktzap.root}
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'mktzap');
                  pushTo(links.mktzap.root);
                }}
              >
                {t('common:menuMktzap')}
              </a>
            </h2>
            <h2>
              <a
                href={links.plans.root}
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'planos');
                  pushTo(links.plans.root);
                }}
              >
                {t('common:menuPlans')}
              </a>
            </h2>
            <h2>
              <a
                href={links.segments.root}
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'segmentos');
                  pushTo(links.segments.root);
                }}
              >
                {t('common:menuSegments')}
              </a>
            </h2>
          </div>
          <div className="footer-menu-column">
            <h2>{t('common:menuResources')}</h2>
            <a
              href={links.blog.root}
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(dataLayerCategory, 'blog');
                pushTo(links.blog.root);
              }}
            >
              {t('common:menuBlog')}
            </a>
            <a
              href={links.blog.materiais.root}
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(dataLayerCategory, 'materiais');
                pushTo(links.blog.materiais.root);
              }}
            >
              {t('common:menuRichMaterials')}
            </a>
            <a
              href={links.whatsAppLinkGenerator.root}
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(dataLayerCategory, 'gerador-de-link-de-whatsapp');
                pushTo(links.whatsAppLinkGenerator.root);
              }}
            >
              {t('common:menuWhatsAppGenerator')}
            </a>
          </div>
          <div className="footer-menu-column">
            <h2>{t('common:menuInstitutional')}</h2>
            <a
              href={links.institutional.root}
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(dataLayerCategory, 'institucional_quem-somos');
                pushTo(links.institutional.root);
              }}
            >
              {t('common:menuWhoWeArea')}
            </a>
            <a
              href={links.institutional.institute.root}
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(dataLayerCategory, 'institucional_instituto');
                pushTo(links.institutional.institute.root);
              }}
            >
              {t('common:menuInstitutionNewWay')}
            </a>
            <a
              href={t('home:jobs.link')}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                pushDataLayer(
                  dataLayerCategory,
                  'institucional_trabalhe-conosco'
                );
                window.open(t('home:jobs.link'), '_blank');
              }}
            >
              {t('common:menuWorkWithUs')}
            </a>
          </div>
        </nav>

        <section className="footer-contact-address">
          <div className="footer-contact">
            <h2 tabIndex="0">{t('common:connect')}</h2>
            <div className="footer-social-media-row">
              <a
                href={t('common:socialMediaLink.linkedin')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'social_likedin');
                  window.open(t('common:socialMediaLink.linkedin'), '_blank');
                }}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  title="Linkedin"
                  titleId="footer-title-linkedin"
                />
              </a>
              <a
                href={t('common:socialMediaLink.facebook')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'social_facebook');
                  window.open(t('common:socialMediaLink.facebook'), '_blank');
                }}
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  title="Facebook"
                  titleId="footer-title-facebook"
                />
              </a>
              <a
                href={t('common:socialMediaLink.youtube')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'social_youtube');
                  window.open(t('common:socialMediaLink.youtube'), '_blank');
                }}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  title="Youtube"
                  titleId="footer-title-youtube"
                />
              </a>
              <a
                href={t('common:socialMediaLink.instagram')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'social_instagram');
                  window.open(t('common:socialMediaLink.instagram'), '_blank');
                }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  title="Instagram"
                  titleId="footer-title-instagram"
                />
              </a>
            </div>
            <p>
              <a
                href={`mailto:${t('common:email')}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  pushDataLayer(dataLayerCategory, 'email');
                  window.open(`mailto:${t('common:email')}`);
                }}
              >
                {t('common:email')}
              </a>
            </p>
          </div>

          <div
            className="footer-address"
            dangerouslySetInnerHTML={{ __html: t('common:location') }}
          />
        </section>
      </Container>
    </Footer>
  );
}

export default FooterNwSite;
